import React from 'react';
import { FiActivity, FiCast, FiServer, FiCode } from "react-icons/fi";
import { IoHardwareChipOutline } from 'react-icons/io5';
import { PiTreeStructureLight } from "react-icons/pi";
import {Link} from "react-router-dom";
import ScrollAnimation from "react-animate-on-scroll";
import { usePageTranslations } from '../../utils/translation';

// const ServiceList = [
//     {
//         icon: <FiActivity />,
//         title: 'Awarded Design',
//         description: 'There are many variations variations  of passages of Lorem Ipsum available, but the majority have suffered.'
//     },
//     {
//         icon: <FiCast />,
//         title: 'Design & Creative',
//         description: 'Passages there are many variations variations  of of Lorem Ipsum available, but the majority have suffered.'
//     },
//     {
//         icon: <FiMap />,
//         title: 'App Development',
//         description: 'Variations There are many variations  of passages of Lorem Ipsum available, but the majority have suffered.'
//     },
// ]

const translations = {
	en: {
        softwareTitle: 'Dedicated Server',
        softwareDes: 'Experience top-notch performance and security with our dedicated servers.',
        embeddedTitle: 'Virtual Server',
        embeddedDes: 'Build an efficient cloud environment with flexible and scalable virtual servers.',
        infraTitle: 'Managed Services',
        infraDes: 'Let our experts manage your Kubernetes clusters, databases, and more.',
	},
	ko: {
        softwareTitle: '단독 서버',
        softwareDes: '최고의 성능과 보안을 제공하는 단독 서버입니다.',
        embeddedTitle: '가상 서버',
        embeddedDes: '유연하고 확장 가능한 가상 서버로 효율적인 클라우드 환경을 구축하세요.',
        infraTitle: '관리형 서비스',
        infraDes: 'Kubernetes 클러스터, 데이터베이스 등을 전문가가 대신 운영해드립니다.',
	},
};

const ServiceOne = ({textAlign, serviceStyle}) => {
    const { t, nt } = usePageTranslations(translations);

    // 서비스 목록
    const ServiceList = [
        {
            icon: <FiServer />,
            title: nt('softwareTitle'),
            description: nt('softwareDes'),
        },
        {
            icon: <FiServer />,
            title: nt('embeddedTitle'),
            description: nt('embeddedDes'),
        },
        {
            icon: <PiTreeStructureLight />,
            title: nt('infraTitle'),
            description: nt('infraDes'),
        },
    ];
    return (
        <div className="row row--15 service-wrapper">
              {ServiceList.map( (val , i) => (
                <div className="col-lg-4 col-md-6 col-sm-6 col-12" key={i}>
                    <ScrollAnimation 
                    animateIn="fadeInUp"
                    animateOut="fadeInOut"
                    animateOnce={true}>
                        <div className={`service ${serviceStyle} ${textAlign}`}>
                            <div className="icon">
                                {val.icon}
                            </div>
                            <div className="content">
                                <h4 className="title w-600"><Link to="#service" dangerouslySetInnerHTML={{__html: val.title}}></Link></h4>
                                <p className="description b1 color-gray mb--0" dangerouslySetInnerHTML={{__html: val.description}}></p>
                            </div>
                        </div>
                    </ScrollAnimation>
                </div>
            ))}
        </div>
    )
}
export default ServiceOne;