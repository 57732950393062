import React from 'react';

const BrandList = [
    {
        image: '/images/brand/packetstream.svg',
        link: 'https://packet.stream/'
    },
    {
        image: '/images/brand/hufs.svg'
    },
    {
        image: '/images/brand/dimigo.png'
    }
]

const BrandFour = ({brandStyle}) => {
    return (
        <ul className={`brand-list ${brandStyle}`}>
            {BrandList.map((data, index) => (
                <li key={index} style={{ flexBasis: 'initial', margin: '20px'}}>
                    <a href={data.link} target="_blank"><img src={`${data.image}`} alt="Brand Image" style={{ height: '50px', width: 'auto', objectFit: 'contain' }}/></a>
                </li>
            ))}
        </ul>
    )
}

export default BrandFour;
