import React from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { usePageTranslations } from '../../utils/translation';

const translations = {
    en: {
        expertise: 'Stable Power',
        expertiseDes: 'We provide uninterrupted power with N+1 UPS, A+B power sources, and N+1 generators.',
        tailoredServices: 'High-Speed Network',
        tailoredServicesDes: 'Offering 200G+ bandwidth connected with over 6 domestic and 5 international carriers for ultra-fast connectivity.',
        reliability: 'Optimal Environmental Control',
        reliabilityDes: 'Ensuring server stability with robust cooling and constant temperature monitoring.'
    },
    ko: {
        expertise: '안정적인 전원',
        expertiseDes: 'N+1 UPS, A+B 전원 공급, N+1 발전기로 끊김 없는 전원을 제공합니다.',
        tailoredServices: '초고속 네트워크',
        tailoredServicesDes: '200G+ 대역폭, 6개 이상의 국내 통신사와 5개 이상의 해외 통신사와 연동된 고속 네트워크를 제공합니다.',
        reliability: '최적의 환경 관리',
        reliabilityDes: '강력한 냉방과 지속적인 온도 모니터링으로 서버의 안정성을 보장합니다.'
    }
};


const AdvanceTabTwo = () => {
    const { t, nt } = usePageTranslations(translations);
    return (
        <div>
            <Tabs>
                <div className="row row--30">
                    <div className="col-lg-8">
                        <div className="advance-tab-content advance-tab-content-1">
                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="/images/tab/stable-power.jpg" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            
                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="/images/tab/high-speed-network.jpg" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>

                            <TabPanel>
                                <div className="tab-content">
                                    <div className="inner">
                                        <div className="thumbnail">
                                            <img src="/images/tab/optimal.jpg" alt="Corporate Images" />
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </div>
                    </div>
                    <div className="col-lg-4 mt_md--30 mt_sm--30">
                        <div className="advance-tab-button advance-tab-button-1 right-align">
                            <TabList className="tab-button-list">
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">{nt('expertise')}</h4>
                                        <p className="description">{nt('expertiseDes')}</p>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">{nt('tailoredServices')}</h4>
                                        <p className="description">{nt('tailoredServicesDes')}</p>
                                    </div>   
                                </Tab>
                                <Tab>
                                    <div className="tab-button">
                                        <h4 className="title">{nt('reliability')}</h4>
                                        <p className="description">{nt('reliabilityDes')}</p>
                                    </div>   
                                </Tab>
                            </TabList>
                        </div>
                    </div>
                    
                </div>
            </Tabs>
        </div>
    )
}
export default AdvanceTabTwo;
